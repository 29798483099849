// Commonly used web colors
$black: #000000;            // Black
$white: #ffffff;            // White
$success-green: #79bd9a;    // Padua
$error-red: #df405a;        // Cerise
$warning-red: #ff5050;      // Sunset Orange
$gold-star: #ca8f04;        // Dark Goldenrod

// Values from the classic Mastodon UI
$classic-base-color: #2c162c;         // Dark Purple
$classic-primary-color: #d0a0d0;      // Light Pink
$classic-secondary-color: #6abafd;    // Light Blue
$classic-highlight-color: #6abafd;    // Light Blue

// Variables for defaults in UI
$base-shadow-color: $black !default;
$base-overlay-background: $black !default;
$base-border-color: $white !default;
$simple-background-color: $white !default;
$primary-text-color: $white !default;
$valid-value-color: $success-green !default;
$error-value-color: $error-red !default;

// Tell UI to use selected colors
$ui-base-color: $classic-base-color !default;                  // Darkest
$ui-base-lighter-color: lighten($ui-base-color, 26%) !default; // Lighter darke$
$ui-primary-color: $classic-primary-color !default;            // Lighter
$ui-secondary-color: $classic-secondary-color !default;        // Lightest
$ui-highlight-color: $classic-highlight-color !default;        // Vibrant

// Language codes that uses CJK fonts
$cjk-langs: ja, ko, zh-CN, zh-HK, zh-TW;
